import React, { useCallback } from 'react';

import {
  FetchProjectsFilterProjects,
  FetchProjectsFilters,
  ProjectFields
} from '../../../projectsTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { IndexTableMainFilter } from '../../../../common/components/filters/IndexTableMainFilter';
import { IndexTablePageHeader } from '../../../../common/components/headers/IndexTablePageHeader';
import { CreateProjectModalButton } from '../../modalButtons/CreateProjectModalButton';

import { ItemsSearchFilter } from '../../../../../utils/ItemsSearchFilter';

import { projectsKeys } from '../../../../../locales/keys';

import { ProjectsPermissions } from '../../../projectsConstants';

interface ProjectsIndexPageHeaderProps {
  projectsFilters: FetchProjectsFilters;
  filterProjects: FetchProjectsFilterProjects;
}

function ProjectsIndexPageHeader({
  projectsFilters,
  filterProjects
}: ProjectsIndexPageHeaderProps) {
  const currentUser = useCurrentUser();

  const handleSearch = useCallback<(name: string) => void>(
    (name: string) => {
      filterProjects({
        ...projectsFilters,
        ...ItemsSearchFilter.setValue(name)
      });
    },
    [filterProjects, projectsFilters]
  );

  return (
    <IndexTablePageHeader
      actionButtons={
        currentUser.hasPermissions(
          ProjectsPermissions.READ_NEW_PROJECT_BUTTON
        ) ? (
          <CreateProjectModalButton />
        ) : undefined
      }
    >
      <IndexTableMainFilter
        fieldName={ProjectFields.NAME}
        value={ItemsSearchFilter.getValue(projectsFilters)}
        i18nPlaceholder={projectsKeys.search}
        onSearch={handleSearch}
        scope="projects"
      />
    </IndexTablePageHeader>
  );
}

export default ProjectsIndexPageHeader;
